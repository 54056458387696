// .po-button {
//   font-family: NunitoSans-Bold, sans-serif;
//   font-size: 16px;
//   line-height: 24px;
// }

// .po-button[p-type="primary"] {
//   color: @color-text-button !important;
//   background: @color-base !important;
//   box-shadow: 0 4px 8px 0 rgb(0 0 0 / 15%) !important;
// }

// .po-button[p-type="primary"]:hover {
//   color: @color-text-button !important;
//   background-color: @color-hover !important;
// }

// .po-button[p-kind="primary"]:hover {
//   color: @color-text-button !important;
//   background-color: @color-hover !important;
// }

// .po-button[p-type="primary"]:disabled {
//   background-color: #b6bdbf !important;
//   border: 1px solid #b6bdbf !important;
//   cursor: not-allowed !important;

//   span {
//     color: #fff !important;
//   }
// }

po-button[p-type="primary"] button:enabled {
    color: @color-text-button !important;
    background: @color-base !important;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 15%) !important;
}

po-button[p-kind="primary"] button:enabled {
    color: @color-text-button !important;
    background: @color-base !important;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 15%) !important;
}

.po-button:is(:focus) {
    outline-color: @color-outline;
    outline-width: 0.2em;
    outline-style: solid;
    border-radius: 3px;
}