.po-table-footer-show-more {
  margin-top: 25px;
}

.po-table-column-label {
  white-space: nowrap;
  min-width: 100px;
  text-align: center;
  border-radius: 16px;
}

.po-table {
  border-collapse: separate;
}

.po-table-overlay {
  background-color: rgba(5, 45, 62, 0.7);
}

.po-search {
  display: none;
}

.po-table-actions {
  display: flex;
  justify-content: right;
  border: none;
}

::ng-deep .po-table .cdk-drop-list {
  height: 44px;
}

.po-table tbody tr td {
  font-size: 14px !important;
  padding: 4px 5px !important;
  text-align: center;
}

.cdk-virtual-scroll-orientation-vertical,
.po-table-main-container {
  scrollbar-color: var(--ats-color-button-background-color-primary)
    rgba(0, 0, 0, 0);
  scrollbar-width: thin;
}

.po-table-column-label {
  text-align: center;
  border-radius: 16px;
  text-transform: none;
  font-family: revert;
  font-size: 14px;
}

.po-table-header-ellipsis {
  font-weight: 700;
  color: var(--ats-color-primary);
  font-family: NunitoSans-Bold, sans-serif !important;
  font-size: 14px;
  padding: 0px 4px;
}

.po-table .cdk-drop-list > th {
  background-color: var(--ats-color-background);
  border-bottom: 2px solid var(--ats-color-primary-light-80);
  padding: 16px 5px !important;
}

.po-table .po-table-row-active td {
  background-color: var(--ats-color-primary-light-80);
}

.po-table th svg {
  width: 16px;
  height: 16px;
}

.po-table th svg path {
  fill: #0a87a6;
  margin-left: -20px;
}

.po-table-header-flex {
  gap: 0px;
}

.po-table-header-icon-unselected {
  margin-left: 5px !important;
}

.po-table-header-icon-unselected:after {
  display: none;
}

.po-table-column {
  text-align: center;
}

.po-table-custom {
  display: flex;
  flex-direction: column;

  .po-table-actions {
    float: right;
    border: none;
  }
}
