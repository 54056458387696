@box-shadow-drag-drop: var(--boxShadowDragDrop);
@color-success: var(--colorSuccess);
@color-plus-button: var(--colorPlusButton);
@color-error: var(--colorError);
@color-warning: var(--colorWarning);
@color-next-step: var(--colorNextStep);
@color-disable-step: var(--colorDisableStep);
@color-title: var(--colorTitle);
@color-status: var(--colorStatus);
@color-outline: var(--colorOutline);
@matrix-item-background: #dddddd;
@matrix-item-previous-committee-background: #4a5c60;
@font-family-bold: NunitoSans-Bold, sans-serif;
@font-family: NunitoSans, sans-serif;

:root {
  --boxShadowDragDrop: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  --colorSuccess: #00b28e;
  --colorPlusButton: #0c9abe;
  --colorError: #c64840;
  --colorNextStep: #4a5c60;
  --colorDisableStep: #b6bdbf;
  --colorWarning: #ea9b3e;
  --colorTitle: #4a5c60;
  --colorBorder: #dadedf;
  --colorStatus: #2c85c8;
  --colorOutline: #2c85c8;
}