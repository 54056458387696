.po-menu-item {
    --outline-color-focused: #0c9abe;
    --colorMenuSeparator: rgba(138, 160, 166, 0.2);
}

.po-menu-item {
    font-family: NunitoSans, sans-serif;
    font-size: 16px;
    font-weight: normal;
    color: #4a5c60;
    line-height: 24px;
    border-top: 1px solid var(--colorMenuSeparator);
    padding: 12px 40px 12px 16px;
}

.po-menu-item-selected {
    font-family: NunitoSans-Bold, sans-serif;
    font-size: 16px;
}

.po-menu-header-template {
    text-align: center;
    position: absolute;
    bottom: 0;
}

.po-icon-menu-open,
.po-icon-menu-close {
    color: @color-linkmenu;
}

.po-menu {
    background-color: @color-menu;
}

.po-menu-item-selected,
.po-menu-item-selected:hover {
    border-left: 3px solid @color-highlight !important;
    color: @color-base;
}

.po-menu-mobile {
    background-color: #fbfbfb;
    color: @color-base;
}

div.po-menu-item.po-menu-item-grouper-up {
    color: @color-base !important;
}

div.po-menu-sub-items .po-menu-item-level-two {
    color: @color-base !important;
}

.po-menu-item-selected:after {
    width: 0;
    color: #4a5c60;
}

div.po-menu-sub-items {
    margin-left: -53px;
}