.po-input {
    font-family: NunitoSans, sans-serif;
    font-size: 16px;
    line-height: 24px;
    -webkit-appearance: textfield;
    appearance: textfield;
    background-color: #fff;
    border: 1px solid #b6bdbf;
    border-radius: 3px;
    box-shadow: inset 0 1px 8px 0 #0000001a;
    height: 44px;
    padding: 0 16px;
    width: 100%;
}

input.po-input:is(:focus) {
    outline-color: @color-outline;
    outline-width: 0.2em;
    outline-style: solid;
    border-radius: 3px;
}

// .po-input {
//   padding: 8px 16px;
// }

// @media only screen and (max-width: 1366px) {
//   .po-input {
//     height: 44px;
//   }
// }

// .ng-valid .po-input:focus {
//   border: 1px solid @color-base !important;
// }

// .ng-valid .po-input:focus {
//   border: 1px solid #0c9abe !important;
// }