div.po-modal-body::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
div.po-modal-body::-webkit-scrollbar-track {
  display: none;
}
div.po-modal-body::-webkit-scrollbar-thumb {
  background-color: var(--colorBase);
  border-radius: 10px;
}
po-button[p-type="primary"] button:enabled {
  color: #ffffff !important;
  background: #0c9abe !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15) !important;
}
po-button[p-kind="primary"] button:enabled {
  color: #ffffff !important;
  background: #0c9abe !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15) !important;
}
.po-button:is(:focus) {
  outline-color: var(--colorOutline);
  outline-width: 0.2em;
  outline-style: solid;
  border-radius: 3px;
}
.po-checkbox-outline:is(:focus) {
  outline-color: var(--colorOutline);
  outline-width: 0.2em;
  outline-style: solid;
  outline-offset: 2px;
  border-radius: 3px;
}
.po-table-footer-show-more {
  margin-top: 25px;
}
.po-table-column-label {
  white-space: nowrap;
  min-width: 100px;
  text-align: center;
  border-radius: 16px;
}
.po-table {
  border-collapse: separate;
}
.po-table-overlay {
  background-color: rgba(5, 45, 62, 0.7);
}
.po-search {
  display: none;
}
.po-table-actions {
  display: flex;
  justify-content: right;
  border: none;
}
::ng-deep .po-table .cdk-drop-list {
  height: 44px;
}
.po-table tbody tr td {
  font-size: 14px !important;
  padding: 4px 5px !important;
  text-align: center;
}
.cdk-virtual-scroll-orientation-vertical,
.po-table-main-container {
  scrollbar-color: var(--ats-color-button-background-color-primary) rgba(0, 0, 0, 0);
  scrollbar-width: thin;
}
.po-table-column-label {
  text-align: center;
  border-radius: 16px;
  text-transform: none;
  font-family: revert;
  font-size: 14px;
}
.po-table-header-ellipsis {
  font-weight: 700;
  color: var(--ats-color-primary);
  font-family: NunitoSans-Bold, sans-serif !important;
  font-size: 14px;
  padding: 0px 4px;
}
.po-table .cdk-drop-list > th {
  background-color: var(--ats-color-background);
  border-bottom: 2px solid var(--ats-color-primary-light-80);
  padding: 16px 5px !important;
}
.po-table .po-table-row-active td {
  background-color: var(--ats-color-primary-light-80);
}
.po-table th svg {
  width: 16px;
  height: 16px;
}
.po-table th svg path {
  fill: #0a87a6;
  margin-left: -20px;
}
.po-table-header-flex {
  gap: 0px;
}
.po-table-header-icon-unselected {
  margin-left: 5px !important;
}
.po-table-header-icon-unselected:after {
  display: none;
}
.po-table-column {
  text-align: center;
}
.po-table-custom {
  display: flex;
  flex-direction: column;
}
.po-table-custom .po-table-actions {
  float: right;
  border: none;
}
.po-input {
  font-family: NunitoSans, sans-serif;
  font-size: 16px;
  line-height: 24px;
  -webkit-appearance: textfield;
  appearance: textfield;
  background-color: #fff;
  border: 1px solid #b6bdbf;
  border-radius: 3px;
  box-shadow: inset 0 1px 8px 0 #0000001a;
  height: 44px;
  padding: 0 16px;
  width: 100%;
}
input.po-input:is(:focus) {
  outline-color: var(--colorOutline);
  outline-width: 0.2em;
  outline-style: solid;
  border-radius: 3px;
}
.po-multiselect-input:is(:focus) {
  outline-color: var(--colorOutline);
  outline-width: 0.2em;
  outline-style: solid;
  border-radius: 3px;
}
.po-menu-item {
  --outline-color-focused: #0c9abe;
  --colorMenuSeparator: rgba(138, 160, 166, 0.2);
}
.po-menu-item {
  font-family: NunitoSans, sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: #4a5c60;
  line-height: 24px;
  border-top: 1px solid var(--colorMenuSeparator);
  padding: 12px 40px 12px 16px;
}
.po-menu-item-selected {
  font-family: NunitoSans-Bold, sans-serif;
  font-size: 16px;
}
.po-menu-header-template {
  text-align: center;
  position: absolute;
  bottom: 0;
}
.po-icon-menu-open,
.po-icon-menu-close {
  color: #0c9abe;
}
.po-menu {
  background-color: #ffffff;
}
.po-menu-item-selected,
.po-menu-item-selected:hover {
  border-left: 3px solid #29b6c5 !important;
  color: #0c9abe;
}
.po-menu-mobile {
  background-color: #fbfbfb;
  color: #0c9abe;
}
div.po-menu-item.po-menu-item-grouper-up {
  color: #0c9abe !important;
}
div.po-menu-sub-items .po-menu-item-level-two {
  color: #0c9abe !important;
}
.po-menu-item-selected:after {
  width: 0;
  color: #4a5c60;
}
div.po-menu-sub-items {
  margin-left: -53px;
}
.tpl-slider-pointer:is(:focus) {
  outline-color: var(--colorOutline);
  outline-width: 0.3em;
  outline-style: solid;
  outline-offset: 2px;
  border-radius: 3px;
}
body {
  width: 100%;
  height: auto;
}
.po-user-bar-logo-text {
  font-family: NunitoSans-Bold, sans-serif;
  font-size: 14px;
  color: #4a5c60;
  vertical-align: middle;
  text-align: left;
  margin-left: 5px;
}
.po-page {
  background-color: #fbfbfb;
}
.po-radio-group-input-checked + .po-radio-group-label:before {
  background-color: #0c9abe;
  border-color: #0c9abe;
}
.po-table-column-icons {
  color: var(--colorStatus) !important;
}
a:hover {
  color: #0a87a6;
}
.po-select-item-selected > .po-select-item,
.po-calendar-box-background-selected,
.po-calendar-box-background-selected:active,
.po-calendar-box-background:active {
  background-color: #0c9abe;
}
.po-select-container .po-select-show > .po-select-button,
.po-select:focus + .po-select-container .po-select-button {
  border: 1px solid #0c9abe !important;
}
.po-button {
  border: 1px solid #0c9abe;
  color: #0c9abe;
  cursor: pointer;
  height: 44px;
  min-width: 70px;
}
.po-button:hover {
  background: #0a87a6;
  color: #ffffff;
  border: 1px solid #0c9abe;
}
.po-button:disabled {
  background-color: transparent;
  border: 1px solid #b6bdbf;
  color: #b6bdbf;
}
.po-button-danger {
  border: 1px solid #c64840;
  color: #c64840;
}
.po-modal-footer {
  padding-top: 8px;
  padding-bottom: 8px;
}
.po-button-primary {
  color: #ffffff;
  background: #0c9abe;
}
.po-button-primary:hover {
  color: #ffffff;
}
.po-field-icon,
.po-select-button,
.po-tab-button,
.po-toolbar-title,
.po-toolbar-profile-item-header-title,
.po-popup-item-default,
.po-calendar-nav .po-calendar-nav-title,
.po-calendar-nav .po-calendar-nav-left,
.po-calendar-nav .po-calendar-nav-right,
.po-switch-button-on > .po-icon-ok,
.po-select-item,
.po-table-header,
.po-page-list-filter-search-link,
.po-table-header-column-manager-button,
.po-modal-header-close-button,
.po-select-item:hover,
.po-select:focus + .po-select-container .po-select-button {
  color: #0c9abe !important;
}
.po-menu-header-template {
  text-align: center;
  position: absolute;
  bottom: 0;
}
.po-switch-container-on,
.po-page-content::-webkit-scrollbar-thumb {
  background-color: #0c9abe;
}
.po-menu-item-grouper-up,
.po-menu-item-grouper-up:hover,
.po-select-item-selected > .po-select-item:hover {
  color: #fff;
}
.ng-valid .po-input:focus {
  border: 1px solid #0c9abe !important;
}
.po-icon-menu-open,
.po-icon-menu-close {
  color: #0c9abe;
}
.po-menu-item {
  border-top: 1px solid rgba(138, 160, 166, 0.2);
}
.po-menu {
  background-color: #ffffff;
}
.po-menu-item-selected,
.po-menu-item-selected:hover {
  border-left: 3px solid #29b6c5 !important;
  color: #0c9abe;
}
.po-menu-mobile {
  background-color: #fbfbfb;
  color: #0c9abe;
}
.po-table-footer-show-more {
  margin-top: 25px;
}
div.po-menu-item.po-menu-item-grouper-up {
  color: #0c9abe !important;
}
div.po-menu-sub-items .po-menu-item-level-two {
  color: #0c9abe !important;
}
.po-checkbox-group-input-checked + .po-checkbox-group-label:before,
.po-checkbox-group-input-indeterminate + .po-checkbox-group-label:before {
  border-color: #0c9abe;
}
.po-button-primary:hover {
  background: #0a87a6 !important;
}
.po-select-button:hover {
  border: 1px solid #0c9abe;
  color: #0c9abe;
}
.po-tab-button-active {
  box-shadow: inset 0 -3px 0 0 #29b6c5;
}
.po-switch-container-on,
.po-page-content::-webkit-scrollbar-thumb {
  background-color: #0c9abe;
}
.general {
  color: #0c9abe !important;
}
.po-field-container-title {
  margin-top: 8px;
}
.po-modal-header {
  padding: 16px 8px;
}
.po-modal[_ngcontent-c5] {
  font-family: "Nunito Sans", sans-serif;
}
@media screen and (min-width: 1367px) {
  .po-button {
    height: 46px;
  }
}
.po-page-header-title.goBack::before {
  content: "\e800";
  cursor: pointer;
  color: var(--colorBase);
  pointer-events: auto;
  font-family: "tpl-icons";
  font-size: 24px;
}
@media only screen and (min-width: 768px) {
  .po-page-header-title.goBack::before {
    position: absolute;
    left: -3vw;
  }
}
@media only screen and (min-width: 1366px) {
  .po-page-header-title.goBack::before {
    top: -2px;
  }
}
.po-page-header-title.goBack {
  pointer-events: none;
  position: relative;
}
.po-page-header-title.goBack.hide-before::before {
  display: none;
}
.po-modal-content {
  padding: 0px;
}
.po-modal-header {
  border-bottom: 1px solid #dadedf;
}
.po-modal-title {
  margin-left: 12px;
  font-weight: bold;
}
.po-modal-footer {
  padding: 24px;
}
.ng-valid .po-input:focus {
  border: 1px solid #0c9abe !important;
}
a:hover {
  color: #0a87a6;
}
.po-select-item-selected > .po-select-item,
.po-calendar-box-background-selected,
.po-calendar-box-background-selected:active,
.po-calendar-box-background:active {
  background-color: #0c9abe;
}
.po-select-container .po-select-show > .po-select-button,
.po-select:focus + .po-select-container .po-select-button {
  border: 1px solid #0c9abe !important;
}
.po-modal-overlay,
.po-table-overlay {
  background-color: rgba(5, 45, 62, 0.7);
}
.po-select-button:hover {
  border: 1px solid #0c9abe;
  color: #0c9abe;
}
.po-tab-button-active {
  box-shadow: inset 0 -3px 0 0 #29b6c5;
}
.po-table-column-label {
  white-space: nowrap;
  min-width: 100px;
  text-align: center;
  border-radius: 16px;
}
.po-row.po-md-12.button {
  display: grid;
  justify-content: end;
}
.po-tag-value {
  color: white;
}
:root {
  --boxShadowDragDrop: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  --colorSuccess: #00b28e;
  --colorPlusButton: #0c9abe;
  --colorError: #c64840;
  --colorNextStep: #4a5c60;
  --colorDisableStep: #b6bdbf;
  --colorWarning: #ea9b3e;
  --colorTitle: #4a5c60;
  --colorBorder: #dadedf;
  --colorStatus: #2c85c8;
  --colorOutline: #2c85c8;
}
body {
  color: #4a5c60;
}
* {
  --ats-color-primary: #0c9ab2;
  --colorBase: #0c9abe;
  --font-family-theme: NunitoSans, sans-serif;
  --line-height-md: 24px;
  --ats-color-primary-light-80: #ceeaf2;
  --ats-color-background: #fff;
  --color-button-background-color-disabled: #f1f1f1;
  --background-disabled: #f1f1f1;
  --color-input-color-disabled: #b2b2b2;
}
.tpl-no-padding {
  padding: 0px !important;
}
.tpl-no-padding-right {
  padding-right: 0px !important;
}
.tpl-no-padding-left {
  padding-left: 0px !important;
}
.tpl-no-padding-sides {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.tpl-float-right {
  float: right;
}
.tpl-color-base {
  color: var(--colorBase);
}
.tpl-link,
.tpl-clickable {
  cursor: pointer;
  color: var(--colorBase);
  font-weight: bold;
}
.tpl-link:hover,
.tpl-clickable:hover {
  filter: brightness(1.1);
}
.tpl-text-align-center {
  text-align: center;
}
.tpl-link {
  color: var(--colorBase);
}
.tpl-visibility-hidden {
  visibility: hidden;
}
.tpl-visibility-visible {
  visibility: visible;
}
.tpl-field-disabled {
  font-family: NunitoSans-Bold, sans-serif;
  font-size: 14px;
  line-height: 24px;
  background-color: #eceeee;
  border: 1px solid #b6bdbf;
  color: #b6bdbf;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  border-radius: 3px;
  vertical-align: middle;
  box-shadow: inset 0 1px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 4px 8px;
}
.tpl-field-enable {
  font-family: NunitoSans-Bold, sans-serif;
  font-size: 14px;
  line-height: 24px;
  background-color: white;
  border: 1px solid #b6bdbf;
  color: #b6bdbf;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  border-radius: 3px;
  vertical-align: middle;
  box-shadow: inset 0 1px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 4px 8px;
}
.tpl-color-success {
  color: var(--colorSuccess);
  border-color: var(--colorSuccess);
}
.tpl-color-error {
  color: var(--colorError);
  border-color: var(--colorError);
}
.tpl-color-warning {
  color: var(--colorWarning);
  border-color: var(--colorWarning);
}
.tpl-bold {
  font-weight: bold;
}
.tpl-label {
  font-family: NunitoSans-Bold, sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: var(--colorTitle);
  display: table-cell;
  vertical-align: sub;
  width: 100%;
  margin-top: 8px;
}
.tpl-icon {
  font-size: 20px;
}
@media only screen and (min-width: 1367px) {
  .tpl-icon {
    font-size: 25px;
  }
}
.tpl-title {
  font-size: 20px;
  font-weight: bold;
  text-decoration: #4a5c60;
  margin-top: 10px;
}
.tpl-subtitle {
  font-size: 20px;
  line-height: 24px;
  display: table-cell;
  vertical-align: sub;
  width: 100%;
}
.sub-title {
  margin-top: 10px;
  margin-bottom: -15px;
  padding-left: 15px;
}
.tpl-clearfix {
  clear: both;
}
.tpl-clearfix:after {
  clear: both;
  content: "";
  display: table;
}
.tpl-container {
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  background-color: white;
  word-wrap: break-word;
  padding: 30px;
}
@media screen and (min-width: 1367px) {
  .tpl-subtitle {
    font-size: 24px;
  }
  .tpl-label {
    margin-top: 16px;
  }
}
.tpl-icon {
  font-size: 20px;
}
@media only screen and (min-width: 1367px) {
  .tpl-icon {
    font-size: 25px;
  }
}
.tpl-subtitle {
  font-size: 20px;
  line-height: 24px;
  display: table-cell;
  vertical-align: sub;
  width: 100%;
}
.tpl-clearfix {
  clear: both;
}
.tpl-clearfix:after {
  clear: both;
  content: "";
  display: table;
}
.tpl-hidden {
  display: none;
}
.custom-scroll::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.custom-scroll::-webkit-scrollbar-track {
  display: none;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background-color: var(--colorBase);
  border-radius: 10px;
}
img.default-checkbox-flagged {
  width: 20px;
}
.modal-default-content-line {
  color: #808080;
}
.custom-div-default-content-vertical-align {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-message {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--colorTitle);
  padding: 15px 15px;
}
.page-bottom {
  margin-bottom: 80px;
}
.line {
  margin-top: 20px;
}
.my-service-title {
  font-weight: bold;
}
.titleAdjustment {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.iconAlignRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.totalCounterAlign {
  padding-left: 6px;
}
