@import "custom-po.less";
@import "definitions.less";

body {
  color: #4a5c60;
}

* {
  --ats-color-primary: #0c9ab2;
  --colorBase: #0c9abe;
  --font-family-theme: NunitoSans, sans-serif;
  --line-height-md: 24px;
  --ats-color-primary-light-80: #ceeaf2;
  --ats-color-background: #fff;
  --color-button-background-color-disabled: #f1f1f1;
  --background-disabled: #f1f1f1;
  --color-input-color-disabled: #b2b2b2;
}

.tpl-no-padding {
  padding: 0px !important;
}

.tpl-no-padding-right {
  padding-right: 0px !important;
}

.tpl-no-padding-left {
  padding-left: 0px !important;
}

.tpl-no-padding-sides {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.tpl-float-right {
  float: right;
}

.tpl-color-base {
  color: var(--colorBase);
}

.tpl-link,
.tpl-clickable {
  cursor: pointer;
  color: var(--colorBase);
  font-weight: bold;
}

.tpl-link:hover,
.tpl-clickable:hover {
  filter: brightness(1.1);
}

.tpl-text-align-center {
  text-align: center;
}

.tpl-link {
  color: var(--colorBase);
}

.tpl-visibility-hidden {
  visibility: hidden;
}

.tpl-visibility-visible {
  visibility: visible;
}

.tpl-field-disabled {
  font-family: @font-family-bold;
  font-size: 14px;
  line-height: 24px;
  background-color: #eceeee;
  border: 1px solid #b6bdbf;
  color: #b6bdbf;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  border-radius: 3px;
  vertical-align: middle;
  box-shadow: inset 0 1px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 4px 8px;
}

.tpl-field-enable {
  font-family: @font-family-bold;
  font-size: 14px;
  line-height: 24px;
  background-color: white;
  border: 1px solid #b6bdbf;
  color: #b6bdbf;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  border-radius: 3px;
  vertical-align: middle;
  box-shadow: inset 0 1px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 4px 8px;
}

.tpl-color-success {
  color: var(--colorSuccess);
  border-color: var(--colorSuccess);
}

.tpl-color-error {
  color: var(--colorError);
  border-color: var(--colorError);
}

.tpl-color-warning {
  color: var(--colorWarning);
  border-color: var(--colorWarning);
}

.tpl-bold {
  font-weight: bold;
}

.tpl-label {
  font-family: @font-family-bold;
  font-size: 14px;
  line-height: 24px;
  color: var(--colorTitle);
  display: table-cell;
  vertical-align: sub;
  width: 100%;
  margin-top: 8px;
}

.tpl-icon {
  font-size: 20px;

  @media only screen and (min-width: 1367px) {
    font-size: 25px;
  }
}

.tpl-title {
  font-size: 20px;
  font-weight: bold;
  text-decoration: #4a5c60;
  margin-top: 10px;
}

.tpl-subtitle {
  font-size: 20px;
  line-height: 24px;
  display: table-cell;
  vertical-align: sub;
  width: 100%;
}

.sub-title {
  margin-top: 10px;
  margin-bottom: -15px;
  padding-left: 15px;
}

.tpl-clearfix {
  clear: both;
}

.tpl-clearfix:after {
  clear: both;
  content: "";
  display: table;
}

.tpl-container {
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  background-color: white;
  word-wrap: break-word;
  padding: 30px;
}

@media screen and (min-width: 1367px) {
  .tpl-subtitle {
    font-size: 24px;
  }

  .tpl-label {
    margin-top: 16px;
  }
}

.tpl-icon {
  font-size: 20px;

  @media only screen and (min-width: 1367px) {
    font-size: 25px;
  }
}

.tpl-subtitle {
  font-size: 20px;
  line-height: 24px;
  display: table-cell;
  vertical-align: sub;
  width: 100%;
}

.tpl-clearfix {
  clear: both;
}

.tpl-clearfix:after {
  clear: both;
  content: "";
  display: table;
}

.tpl-hidden {
  display: none;
}

.custom-scroll::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.custom-scroll::-webkit-scrollbar-track {
  display: none;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: var(--colorBase);
  border-radius: 10px;
}

img.default-checkbox-flagged {
  width: 20px;
}

.modal-default-content-line {
  color: #808080;
}

.custom-div-default-content-vertical-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-message {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--colorTitle);
  padding: 15px 15px;
}

.page-bottom {
  margin-bottom: 80px;
}

.line {
  margin-top: 20px;
}

.my-service-title {
  font-weight: bold;
}

.titleAdjustment {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.iconAlignRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.totalCounterAlign {
  padding-left: 6px;
}
