@import "definitions.less";
@import "definitions-theming.less";
@import "./custom-po/custom-po-modal.less";
@import "./custom-po/custom-po-button.less";
@import "./custom-po/custom-po-checkbox.less";
@import "./custom-po/custom-po-table.less";
@import "./custom-po/custom-po-input.less";
@import "./custom-po/custom-po-calendar.less";
@import "./custom-po/custom-po-multiselect.less";
@import "./custom-po/custom-po-menu.less";
@import "./custom-po/custom-po-slider.less";

body {
  width: 100%;
  height: auto;
}

.po-user-bar-logo-text {
  font-family: NunitoSans-Bold, sans-serif;
  font-size: 14px;
  color: #4a5c60;
  vertical-align: middle;
  text-align: left;
  margin-left: 5px;
}

.po-page {
  background-color: @color-background;
}

.po-radio-group-input-checked+.po-radio-group-label:before {
  background-color: @color-base;
  border-color: @color-base;
}

.po-table-column-icons {
  color: @color-status !important;
}

a:hover {
  color: @color-hover;
}

.po-select-item-selected>.po-select-item,
.po-calendar-box-background-selected,
.po-calendar-box-background-selected:active,
.po-calendar-box-background:active {
  background-color: @color-base;
}

.po-select-container .po-select-show>.po-select-button,
.po-select:focus+.po-select-container .po-select-button {
  border: 1px solid @color-base !important;
}

.po-button {
  border: 1px solid @color-base;
  color: @color-base;
  cursor: pointer;
  height: 44px;
  min-width: 70px;
}

.po-button:hover {
  background: @color-hover;
  color: @color-text-button;
  border: 1px solid @color-base;
}

.po-button:disabled {
  background-color: transparent;
  border: 1px solid #b6bdbf;
  color: #b6bdbf;
}

.po-button-danger {
  border: 1px solid #c64840;
  color: #c64840;
}

.po-modal-footer {
  padding-top: 8px;
  padding-bottom: 8px;
}

.po-button-primary {
  color: @color-text-button;
  background: @color-base;
}

.po-button-primary:hover {
  color: @color-text-button;
}

.po-field-icon,
.po-select-button,
.po-tab-button,
.po-toolbar-title,
.po-toolbar-profile-item-header-title,
.po-popup-item-default,
.po-calendar-nav .po-calendar-nav-title,
.po-calendar-nav .po-calendar-nav-left,
.po-calendar-nav .po-calendar-nav-right,
.po-switch-button-on>.po-icon-ok,
.po-select-item,
.po-table-header,
.po-page-list-filter-search-link,
.po-table-header-column-manager-button,
.po-modal-header-close-button,
.po-select-item:hover,
.po-select:focus+.po-select-container .po-select-button {
  color: @color-base !important;
}

.po-menu-header-template {
  text-align: center;
  position: absolute;
  bottom: 0;
}

.po-switch-container-on,
.po-page-content::-webkit-scrollbar-thumb {
  background-color: @color-base;
}

.po-menu-item-grouper-up,
.po-menu-item-grouper-up:hover,
.po-select-item-selected>.po-select-item:hover {
  color: #fff;
}

.ng-valid .po-input:focus {
  border: 1px solid @color-base !important;
}

.po-icon-menu-open,
.po-icon-menu-close {
  color: @color-linkmenu;
}

.po-menu-item {
  border-top: 1px solid @color-menu-separator;
}

.po-menu {
  background-color: @color-menu;
}

.po-menu-item-selected,
.po-menu-item-selected:hover {
  border-left: 3px solid @color-highlight !important;
  color: @color-base;
}

.po-menu-mobile {
  background-color: #fbfbfb;
  color: @color-base;
}

.po-table-footer-show-more {
  margin-top: 25px;
}

div.po-menu-item.po-menu-item-grouper-up {
  color: @color-base !important;
}

div.po-menu-sub-items .po-menu-item-level-two {
  color: @color-base !important;
}

.po-checkbox-group-input-checked+.po-checkbox-group-label:before,
.po-checkbox-group-input-indeterminate+.po-checkbox-group-label:before {
  border-color: @color-base;
}

.po-button-primary:hover {
  background: @color-hover !important;
}

.po-select-button:hover {
  border: 1px solid @color-base;
  color: @color-base;
}

.po-tab-button-active {
  box-shadow: inset 0 -3px 0 0 @color-highlight;
}

.po-switch-container-on,
.po-page-content::-webkit-scrollbar-thumb {
  background-color: @color-base;
}

.general {
  color: @color-base !important;
}

.po-field-container-title {
  margin-top: 8px;
}

.po-modal-header {
  padding: 16px 8px;
}

.po-modal[_ngcontent-c5] {
  font-family: "Nunito Sans", sans-serif;
}

@media screen and (min-width: 1367px) {
  .po-button {
    height: 46px;
  }
}

.po-page-header-title.goBack::before {
  content: "\e800";
  cursor: pointer;
  color: var(--colorBase);
  pointer-events: auto;
  font-family: "tpl-icons";
  font-size: 24px;
}

@media only screen and (min-width: 768px) {
  .po-page-header-title.goBack::before {
    position: absolute;
    left: -3vw;
  }
}

@media only screen and (min-width: 1366px) {
  .po-page-header-title.goBack::before {
    top: -2px;
  }
}

.po-page-header-title.goBack {
  pointer-events: none;
  position: relative;
}

.po-page-header-title.goBack.hide-before::before {
  display: none;
}

.po-modal-content {
  padding: 0px;
}

.po-modal-header {
  border-bottom: 1px solid #dadedf;
}

.po-modal-title {
  margin-left: 12px;
  font-weight: bold;
}

.po-modal-footer {
  padding: 24px;
}

.ng-valid .po-input:focus {
  border: 1px solid #0c9abe !important;
}

a:hover {
  color: #0a87a6;
}

.po-select-item-selected>.po-select-item,
.po-calendar-box-background-selected,
.po-calendar-box-background-selected:active,
.po-calendar-box-background:active {
  background-color: #0c9abe;
}

.po-select-container .po-select-show>.po-select-button,
.po-select:focus+.po-select-container .po-select-button {
  border: 1px solid #0c9abe !important;
}

.po-modal-overlay,
.po-table-overlay {
  background-color: rgba(5, 45, 62, 0.7);
}

.po-select-button:hover {
  border: 1px solid #0c9abe;
  color: #0c9abe;
}

.po-tab-button-active {
  box-shadow: inset 0 -3px 0 0 #29b6c5;
}

.po-table-column-label {
  white-space: nowrap;
  min-width: 100px;
  text-align: center;
  border-radius: 16px;
}

.po-row.po-md-12.button {
  display: grid;
  justify-content: end;
}

.po-tag-value {
  color: white;
}