div.po-modal-body::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

div.po-modal-body::-webkit-scrollbar-track {
    display: none;
}

div.po-modal-body::-webkit-scrollbar-thumb {
    background-color: var(--colorBase);
    border-radius: 10px;
}